require("bootstrap")
require("./stylesheets/tokens.css")
require("./stylesheets/application.scss")
require("@fortawesome/fontawesome-free/js/all")
require("rails-ujs").start()
import amplitude from "amplitude-js"
import mixpanel from "mixpanel-browser"
import dateFormatter from "./utils/dateFormatter"
import { ExternalProjectLockToggleButton } from "./components/ExternalProjectLockToggleButton"
import { render } from "react-dom"
import Cookies from "js-cookie"

const amplitudeData = JSON.parse(document.getElementById("amplitude-data").text)
amplitude.getInstance().init(amplitudeData.apiKey, null, {
  saveEvents: true,
  includeUtm: true,
  includeReferrer: true,
})
if (amplitudeData.userId) {
  amplitude.getInstance().setUserId(amplitudeData.userId)
  amplitude.getInstance().setUserProperties(amplitudeData.userProperties)
}

const mixpanelDataElement = document.getElementById("mixpanel-data")
if (mixpanelDataElement) {
  const mixpanelData = JSON.parse(mixpanelDataElement.text)
  mixpanel.init(mixpanelData.token, {
    debug: mixpanelData.isDebug,
    persistence: "localStorage",
  })
  mixpanel.identify(mixpanelData.userId)
  window.mixpanel = mixpanel
  const installationCookie = `mp-install-${mixpanelData.userId}`
  const incognitoCookie = `mp-incognito-${mixpanelData.userId}`
  const observer = new MutationObserver(() => {
    if (!Cookies.get(installationCookie)) {
      window.mixpanel.track("extension:installed")
      Cookies.set(installationCookie, "1")
    }
    if (
      !Cookies.get(incognitoCookie) &&
      (Boolean(Number(document.body.dataset.extensionIncognitoAllowed)) ||
        Boolean(Number(document.body.dataset.extensionIncognitoAllowedMv3)))
    ) {
      window.mixpanel.track("extension:incognito-allowed")
      Cookies.set(incognitoCookie, "1")
    }
  })
  window.addEventListener("DOMContentLoaded", () => {
    observer.observe(document.body, {
      attributes: true,
      attributeFilter: [
        "data-extension-id",
        "data-extension-id-mv3",
        "data-extension-incognito-allowed",
        "data-extension-incognito-allowed-mv3",
      ],
    })
  })
}

// Expose instance for the editor
window.amplitude = amplitude

// https://github.com/rails/rails/blob/53331b478d32e42a8155414379b2edbe4277a3a4/actionview/app/assets/javascripts/rails-ujs/start.coffee#L14
// If we import rails-ujs from the editor, the above line throws an error.
// To prevent it, we remove jQuery.rails.
jQuery.rails = null

window.initTooltip = function initTooltip() {
  $("[data-tooltip]").tooltip({
    animation: false,
    trigger: "hover",
  })
}

$(function () {
  window.$ = $
  window.initTooltip()

  $("form.js-prevent-multi-submit").one("submit", function () {
    const $self = $(this)
    $self.find(":submit").attr("disabled", true)
    $self.on("submit", function (event) {
      event.preventDefault()
    })
  })

  dateFormatter.apply()

  const containerEl = document.getElementById(
    "external-project-lock-toggle-button-container"
  )

  if (containerEl) {
    render(<ExternalProjectLockToggleButton />, containerEl)
  }
})
