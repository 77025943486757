import { parseJSON } from "date-fns"

class DateFormatter {
  locale: string
  timeZone: string
  months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]

  constructor() {
    this.locale = $("html").attr("lang") || "en"
    this.timeZone = $("meta[name=timezone]").attr("content") || "utc"
  }

  apply() {
    $("[data-timestamp]").each((_, element) => {
      this.setTextFromTimestamp(element)
    })

    $(".js-triggerable-table").on("htmlChanged", () => {
      $("[data-timestamp]").each((_, element) => {
        this.setTextFromTimestamp(element)
      })
    })
  }

  formatJSON(date: string) {
    return this.formatDate(parseJSON(date))
  }

  formatDate(rawDate: Date) {
    const localDate = new Date(
      rawDate.toLocaleString(this.locale, { timeZone: this.timeZone })
    )
    const year = localDate.getFullYear()
    const month = localDate.getMonth() + 1
    const date = localDate.getDate()
    const hours = localDate.getHours()
    const rawMinutes = localDate.getMinutes()
    const minutes = rawMinutes < 10 ? `0${rawMinutes}` : rawMinutes

    const now = new Date()
    const isThisYear = rawDate.getFullYear() === now.getFullYear()
    const isToday =
      isThisYear &&
      rawDate.getMonth() === now.getMonth() &&
      rawDate.getDate() === now.getDate()
    const isYesterday =
      isThisYear &&
      rawDate.getMonth() === now.getMonth() &&
      rawDate.getDate() + 1 === now.getDate()

    const hourMinute = `${hours}:${minutes}`
    switch (this.locale) {
      case "ja":
        if (isToday) {
          return `今日 ${hourMinute}`
        } else if (isYesterday) {
          return `昨日 ${hourMinute}`
        } else if (isThisYear) {
          return `${month}月${date}日 ${hourMinute}`
        } else {
          return `${year}年${month}月${date}日 ${hourMinute}`
        }
      default:
        if (isToday) {
          return `Today, ${hourMinute}`
        } else if (isYesterday) {
          return `Yesterday, ${hourMinute}`
        } else if (isThisYear) {
          return `${this.months[month - 1]} ${date}, ${hourMinute}`
        } else {
          return `${this.months[month - 1]} ${date}, ${year}, ${hourMinute}`
        }
    }
  }

  setTextFromTimestamp(element: HTMLElement) {
    const $element = $(element)
    const unixTime = parseInt($element.attr("data-timestamp") || "0", 10)

    if (unixTime > 0) {
      $element.text(this.formatDate(new Date(unixTime * 1000)))
    } else {
      $element.text("-")
    }
  }
}

export default new DateFormatter()
