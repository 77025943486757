import { useEffect, useState } from "react"
import Cookies from "js-cookie"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLock, faUnlock } from "@fortawesome/free-solid-svg-icons"

const COOKIE_NAME = "external_project_unlocked"

export const ExternalProjectLockToggleButton: React.FC = () => {
  const [externalProjectUnlocked, setExternalProjectUnlocked] = useState(false)

  useEffect(() => {
    const checkCookie = () => {
      const value = Cookies.get(COOKIE_NAME)

      setExternalProjectUnlocked(value === "1")
    }

    const timer = setInterval(checkCookie, 100)

    return () => {
      clearInterval(timer)
    }
  }, [setExternalProjectUnlocked])

  return (
    <button
      className="btn btn-link"
      style={{
        display: "flex",
        marginLeft: "18px",
        color: "white",
        opacity: externalProjectUnlocked ? 1 : 0.3,
      }}
      title={externalProjectUnlocked ? "Editing enabled" : "Editing disabled"}
      onClick={() => {
        const nextUnlocked = !externalProjectUnlocked

        // expires in 1 minute
        const expires = new Date(new Date().getTime() + 60 * 1000)

        Cookies.set(COOKIE_NAME, nextUnlocked ? "1" : "0", {
          expires,
          path: "/",
        })
      }}
    >
      <FontAwesomeIcon icon={externalProjectUnlocked ? faUnlock : faLock} />
    </button>
  )
}
